import './estilo.css'
import logo from '../../img/brands/Logo1Comepi.png';
function Logo() {
    return (
        <div className='logo'>
            <img
                src={logo}
                alt='logo'
                className='logo-img'
            ></img>
        </div>
    )
}

export default Logo