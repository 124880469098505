import './estilo.css';
import React, { useState } from 'react';
import Alert from '@mui/material/Alert/index.js';
import AlertTitle from '@mui/material/AlertTitle/index.js';
import Stack from '@mui/material/Stack/index.js';
import Button from '@mui/material/Button/index.js';

function ErrorAlert({ message }) {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
      };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Alert className="error-alert" severity="error" open={open}>
                <AlertTitle>Erro</AlertTitle>
                {message}
            </Alert>
        </Stack>
    );
};

export default ErrorAlert