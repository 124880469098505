import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import Logo from './Componentes/Logo/index';
// import ErrorAlert from './Componentes/Alert/index';
import WebcamContainer from './Componentes/Webcam/index';
import ValidaCpf from './Componentes/ValidaCpf/index.js';
import QrCodeLeitor from './Componentes/QrCode/index';
import RegistroLoja from './Componentes/RegistroLoja/index.js';
import { CampoInput, CampoInputNome, CampoInputRepresentante, CampoInputLoja } from './Componentes/Input/index.js';
import CampoButton from './Componentes/ButtonForm/index';
import { BsQrCodeScan } from 'react-icons/bs/index.js';
// import Fingerprint2 from 'fingerprintjs2';
import { loadFingerprintJS } from './Componentes/fingerprint/index.js';
import './App.css';


function App() {

  const url = 'https://cosme3347.c35.integrator.host/backend';
  //const url = 'http://localhost:50303';

  const [showRegisterScreen, setShowRegisterScreen] = useState(false);
  const [identify, setIdentify] = useState('');
  const [lojas, setLojas] = useState([]);
  const [loja, setLoja] = useState('');
  const [qrCodeData, setQrCode] = useState('');
  const webcamRef = useRef(null);
  const [nome, setNome] = useState('');
  const [representante, setRepresentante] = useState('');
  const [promotorId, setPromotorId] = useState('');
  const [empresaId, setEmpresa] = useState('');
  const [dados, setDados] = useState('');
  const [cpfQr, setCpfFromQRCode] = useState('');
  const [dataRegistro, setDataRegistro] = useState('');
  // Defina o estado para armazenar as informações do registro de entrada
  const [registroEntrada, setRegistroEntrada] = useState(null);

  useEffect(() => {
    const loadFingerprint = async () => {
      try {
        const visitorId = await loadFingerprintJS(); // Usa o wrapper para carregar o FingerprintJS
        verificarIdentificadorPeriodicamente();

      } catch (error) {
        console.error('Erro ao obter o fingerprint:', error);
      }
    };

    const verificarIdentificadorPeriodicamente = async () => {
      try {
        const visitorId = await loadFingerprintJS(); // Carrega o identificador do visitante
        console.log(visitorId);
        if (visitorId) { // Verifica se o identificador não está vazio
          const response = await axios.get(`${url}/loja/validation/${visitorId}`);
          console.log(response);
          const identificadorValido = response.data.found;
          setShowRegisterScreen(!identificadorValido);
          console.log(identificadorValido);
          setIdentify(visitorId);
        } else {
          console.error('visitorId está vazio');
        }
      } catch (error) {
        // Tratamento de erros
        if (error.response) {
          console.error('Erro de resposta da API:', error.response.data);
        } else if (error.request) {
          console.error('Erro de requisição:', error.request);
        } else {
          console.error('Erro ao processar requisição:', error.message);
        }
        console.error('Erro ao verificar identificador:', error);
      }
    };

    loadFingerprint();

    // Define o intervalo de tempo para verificar o identificador (por exemplo, a cada 5 segundos)
   const interval = setInterval(verificarIdentificadorPeriodicamente, 1000);

   // Limpa o intervalo ao desmontar o componente para evitar vazamento de memória
   return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    axios.get(`${url}/empresas`)
      .then((response) => {
        setLojas(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar lojas:', error);
      });
  }, []);

  useEffect(() => {

    const loadFingerprint = async () => {
      try {
        const visitorId = await loadFingerprintJS(); // Usa o wrapper para carregar o FingerprintJS

        axios.get(`${url}/consulta/loja/identificador/${visitorId}`
        ).then((response) => {
          setLojas(response.data); // Define a lista de lojas
          setLoja(response.data[0].nome);
          setEmpresa(response.data[0].loja_id);
        })
          .catch((error) => {
            console.error('Erro ao buscar dados:', error);
          });

      } catch (error) {
        console.error('Erro ao obter o fingerprint:', error);
      }
    };

    loadFingerprint();

  }, [dados]);

  // Atualiza a data e hora em tempo real em intervalos regulares
  useEffect(() => {
    const interval = setInterval(() => {
      setDataRegistro(moment.tz(new Date(), 'America/Manaus').format('YYYY-MM-DD HH:mm:ss'));
    }, 1000); // Atualiza a cada segundo

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente

  }, []);

  useEffect(() => {
    // Função para verificar se já existe um registro de entrada para o promotor na empresa
    const verificarRegistroEntradaAPI = async (promotorId, empresaId) => {
      try {
        // Faça uma solicitação GET para seu endpoint de backend para verificar o registro de entrada
        const response = await axios.get(`${url}/registro/entrada/${promotorId}/${empresaId}`);
        // Se houver um registro de entrada, retorne os dados
        if (response.data) {
          setRegistroEntrada(response.data); // Define o estado com as informações do registro de entrada
        } else {
          setRegistroEntrada(null); // Se não houver registro de entrada, define o estado como null
        }
      } catch (error) {
        console.error('Erro ao verificar registro de entrada:', error);
        setRegistroEntrada(null); // Em caso de erro, define o estado como null
      }
    };

    // Verifica o registro de entrada apenas se promotorId e empresaId estiverem definidos
    if (promotorId && empresaId) {
      verificarRegistroEntradaAPI(promotorId, empresaId);
    }
  }, [promotorId, empresaId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifica se já existe um registro de entrada para o promotor na empresa
    const registroEntradaExistente = registroEntrada !== null;

    // Define o tipo de acesso com base na existência do registro de entrada
    const tipoAcesso = registroEntradaExistente ? 'Saída' : 'Entrada';

    // Exibe a mensagem de confirmação, informando se é entrada ou saída
    if (registroEntrada === null) {
      Swal.fire({
        title: `Deseja mesmo realizar seu acesso de ${tipoAcesso}?`,
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const imageSrc = webcamRef.current.getScreenshot();
          try {
            const response = await fetch(`${url}/registro`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ promotorId, empresaId, imageSrc, dataRegistro, tipoAcesso }), // Inclui o tipoAcesso no corpo da requisição
            });
            if (response.ok) {
              Swal.fire('Acesso salvo com sucesso!', '', 'success');
              // Se for registro de saída, mostra os dados do registro de entrada
              if (registroEntradaExistente && tipoAcesso === 'Saída') {
                Swal.fire({
                  title: 'Acesso de Entrada',
                  icon: 'info',
                  confirmButton: 'btn btn-success',
                  confirmButtonText: 'OK!'
                });
              }
              Swal.fire({
                title: `Acesso de ${tipoAcesso} realizado com sucesso!`, // Exibe se é entrada ou saída
                text: `Nome: ${nome}`,
                icon: 'success',
                confirmButton: 'btn btn-success',
                confirmButtonText: 'OK!'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              Swal.fire('Erro ao salvar o acesso!', '', 'error');
            }
          } catch (error) {
            console.error('Erro ao enviar dados para o servidor:', error);
            Swal.fire('Erro ao enviar dados para o servidor', '', 'error');
          }
        } else if (result.isDenied) {
          Swal.fire('Acesso não foi realizado!', '', 'info');
        }
      });
    } else {
      Swal.fire({
        title: `Deseja mesmo realizar seu acesso de ${tipoAcesso}?`,
        showDenyButton: true,
        html: `<h4>Acesso de Entrada </h4><p>Data e Hora: ${moment(registroEntrada.dataHoraEntrada).utcOffset(-3).format('DD/MM/YYYY HH:mm:ss')}</p><p>Promotor: ${registroEntrada.nomePromotor}</p><p>Loja: ${registroEntrada.nomeEmpresa}</p>`,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const imageSrc = webcamRef.current.getScreenshot();
          try {
            const response = await fetch(`${url}/registro`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ promotorId, empresaId, imageSrc, dataRegistro, tipoAcesso }), // Inclui o tipoAcesso no corpo da requisição
            });
            if (response.ok) {
              Swal.fire('Acesso salvo com sucesso!', '', 'success');
              // Se for registro de saída, mostra os dados do registro de entrada
              if (registroEntradaExistente && tipoAcesso === 'Saída') {
                Swal.fire({
                  title: 'Acesso de Entrada',
                  html: `<p>Data e Hora: ${registroEntrada.dataHoraEntrada}</p>`, // Adicione mais informações se necessário
                  icon: 'info',
                  confirmButton: 'btn btn-success',
                  confirmButtonText: 'OK!'
                });
              }
              Swal.fire({
                title: `Acesso de ${tipoAcesso} realizado com sucesso!`, // Exibe se é entrada ou saída
                text: `Nome: ${nome}`,
                icon: 'success',
                confirmButton: 'btn btn-success',
                confirmButtonText: 'OK!'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              Swal.fire('Erro ao salvar o registro!', '', 'error');
            }
          } catch (error) {
            console.error('Erro ao enviar dados para o servidor:', error);
            Swal.fire('Erro ao enviar dados para o servidor', '', 'error');
          }
        } else if (result.isDenied) {
          Swal.fire('Acesso não foi realizado!', '', 'info');
        }
      });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className='App'>
          <form onSubmit={handleSubmit} className='centered-container'>
            {showRegisterScreen ? (
              <RegistroLoja identify={identify} lojas={lojas} onSubmit={handleSubmit} />
            ) : (
              // Renderiza o formulário principal se showRegisterScreen for falso
              qrCodeData ? (
                <div className='row'>
                  <div className='row'>
                    <div className='col'>
                      <div className='logo-form'>
                        <Logo />
                      </div>
                      <h1>Realize seu acesso</h1>
                      <CampoInput cpf={cpfQr} />
                      <CampoInputNome nome={nome} />
                      <CampoInputRepresentante representante={representante} />
                      <CampoInputLoja loja={loja} setLoja={setLoja} identify={identify} url={url} setDados={setDados} />
                      <CampoButton disabled={!loja} />
                    </div>
                    <div className='col'>
                      <WebcamContainer webcamRef={webcamRef} registroEntrada={registroEntrada} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className='row'>
                  <div className='colQrcode'>
                    <div className='logo-form'>
                      <Logo />
                    </div>
                    <h1>Faça a leitura do seu QR Code</h1>
                    <div className='qrCodeIcon'>
                      <BsQrCodeScan color='#9a0c72' size="30%" />
                    </div>
                    <h3>Ou entre com seu CPF <ValidaCpf
                      onQRCodeData={setQrCode}
                      setCpfFromQRCode={setCpfFromQRCode}
                      setNome={setNome}
                      setPromotorId={setPromotorId}
                      setRepresentante={setRepresentante}
                    /></h3>
                    <h3>E após realizar a validação abrirá para realizar o acesso</h3>
                  </div>
                  <div className='colQrcode'>
                    <QrCodeLeitor
                      onQRCodeData={setQrCode}
                      setCpfFromQRCode={setCpfFromQRCode}
                      setNome={setNome}
                      setPromotorId={setPromotorId}
                      setRepresentante={setRepresentante} />
                  </div>
                </div>
              )
            )}
          </form>
        </div>
      </header>
    </div>
  );
}

export default App;