// fingerprint.js
const FingerprintJS = require('@fingerprintjs/fingerprintjs');

export const loadFingerprintJS = () => {
  return new Promise((resolve, reject) => {
    FingerprintJS.load()
      .then(fp => fp.get())
      .then(result => {
        resolve(result.visitorId);
      })
      .catch(error => {
        reject(error);
      });
  });
};