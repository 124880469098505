import './estilo.css';
import { FcWebcam } from 'react-icons/fc/index.js';

function CampoButton({ disabled }) { // Adicione a propriedade `disabled` aqui
    return (
        <div className='button-area'>
            <button type="submit" className="button" disabled={disabled}>
                <FcWebcam size="28px"/> Registrar Acesso
            </button>
        </div>
    );
};

export default CampoButton;
