import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './estilo.css';

const RegistroLoja = ({ identify, lojasProp }) => {
    const [lojaSelecionada, setLojaSelecionada] = useState('');
    const [senha, setSenha] = useState('');
    const [lojas, setLojas] = useState(lojasProp);
    const [loading, setLoading] = useState(true);
    const [botaoHabilitado, setBotaoHabilitado] = useState(false);

    const url = 'https://cosme3347.c35.integrator.host/backend';
    //const url = 'http://localhost:50301';

    useEffect(() => {
        // Função para buscar a lista de lojas
        const fetchLojas = async () => {
            try {
                const response = await axios.get(url + '/loja/listar');
                setLojas(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar lista de lojas:', error);
            }
        };

        // Chama a função de busca apenas uma vez quando o componente for montado
        fetchLojas();
    }, []);

    // Função para verificar se todos os campos foram preenchidos
    useEffect(() => {
        if (lojaSelecionada && senha) {
            setBotaoHabilitado(true);
        } else {
            setBotaoHabilitado(false);
        }
    }, [lojaSelecionada, senha]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Verificar se a senha está correta
        if (senha === 't0rt4d3l1m40') {
            try {
                // Enviar os dados para o backend via POST
                await axios.post(url + '/registrar/loja', {
                    identificador: identify,
                    loja: lojaSelecionada,
                    senha: senha
                });
                // Limpar os campos após o registro
                setLojaSelecionada('');
                setSenha('');
                alert('Registro realizado com sucesso!');
                window.location.reload();
            } catch (error) {
                console.error('Erro ao registrar loja:', error);
                alert('Erro ao registrar loja. Por favor, tente novamente.');
            }
        } else {
            // Se a senha estiver incorreta, exibe um alerta
            alert('Senha incorreta. Por favor, insira a senha correta.');
        }
    };

    return (
        <div>
            <h1>Tela de Registro - ADM</h1>
            <div className="input-group">
                <label>Identificador</label>
                <input type="text" value={identify} readOnly />
            </div>
            <div className="input-group">
                <label>Loja</label>
                <select value={lojaSelecionada} onChange={(e) => setLojaSelecionada(e.target.value)}>
                    <option disabled value="">Selecione a loja</option>
                    {lojas && lojas.map && lojas.map((loja) => (
                        <option key={loja.id} value={loja.id}>
                            {loja.nome}
                        </option>
                    ))}
                </select>
            </div>
            <div className="input-group">
                <label>Senha</label>
                <input type="password" placeholder="Digite sua senha" value={senha} onChange={(e) => setSenha(e.target.value)} />
            </div>
            <button className="botao-registrar" disabled={!botaoHabilitado} onClick={handleSubmit}>Registrar</button>
        </div>
    );
};

export default RegistroLoja;
